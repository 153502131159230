<template>
    <div>
        <div class="flex justify-end">
            <a :href="billingPortalUrl" target="_blank">
                <el-button type="primary" :loading="$wait.is('loading')" :disabled="!userCan('read settings')">{{ $t('settings.update_credit_card') }}</el-button>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            billingPortalUrl: '',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('settings.settings_billing'));
        this.$store.commit('setActiveMenuItem', '99-2');

        this.getBillingPortalUrl();
    },

    methods: {
        async getBillingPortalUrl() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/billing/get_billing_portal_url`);
            this.billingPortalUrl = data;
            this.$wait.end('loading');
        },
    },
};
</script>
